// Products Page
$(".grid-btn").on("click", function () {
    $(this).addClass("active");
    $(".list-btn").removeClass("active");
    $(".products-section #item-grid")
        .removeClass("list")
        .addClass("grid");
    $(".products-section #item-grid .item-card")
        .addClass("col-lg-4 col-md-6");
    updateMargins();
});

$(".list-btn").on("click", function () {
    $(this).addClass("active");
    $(".grid-btn").removeClass("active");
    $(".products-section #item-grid")
        .removeClass("grid")
        .addClass("list");
    $(".products-section #item-grid .item-card")
        .removeClass("col-lg-4 col-md-6")
        .addClass("col-xl-6");
    updateMargins();
});

function updateMargins() {
  const container = document.querySelector('#item-grid');
  const items = Array.from(document.querySelectorAll('.item-card'));

  if (container && items.length > 0) {
    const containerWidth = container.clientWidth;
    const itemWidth = items[0].clientWidth;
    const itemsPerRow = Math.floor(containerWidth / itemWidth);

    // Reset margins
    items.forEach(item => {
      item.style.marginLeft = '';
      item.style.marginRight = '';
    });

    // Set margins for the first and last items in each row
    items.forEach((item, index) => {
      if (index % itemsPerRow === 0) {
        item.style.marginLeft = '0'; // First item in row
      }
      if ((index + 1) % itemsPerRow === 0 || index === items.length - 1) {
        item.style.marginRight = '0'; // Last item in row
      }
    });
  }
}

window.addEventListener('load', updateMargins);
window.addEventListener('resize', updateMargins);

/* Filter sidebar */
  $(document).ready(function () {
    $(".filter-btn").on("click", function () {
      $("#filter-sidebar").addClass("active");
      $(".filter-overlay").addClass("visible");
    });

    $("#filterCollapseX").on("click", function () {
      $(".filter-overlay").removeClass("visible");
      $("#filter-sidebar").removeClass("active");
    });

    $(".filter-overlay").on("click", function () {
      $("#filter-sidebar").removeClass("active");
      $(".filter-overlay").removeClass("visible");
    });
  });

  /* Product Details */

  $(document).ready(function () {
    const pageTitle = document.getElementById('section-title');

    
    $(".tabs-btn a").click(function (e) {
      e.preventDefault();

      $(".tabs-btn a").removeClass("active");
      $(this).addClass("active");

      var tabId = $(this).attr("href");
      $(".tab-pane").removeClass("show active");
      $(tabId).addClass("show active");

      pageTitle.textContent = this.getAttribute('data-title');
    });

});

const activeImage = document.getElementById("active-image");
const activeVideo = document.getElementById("active-video");
const zoomedImg = document.getElementById("zoomed-img");

// Handle image click event
const swiperSlides = document.querySelectorAll(".swiper-slide img");
swiperSlides.forEach((slide) => {
  slide.addEventListener("click", function () {
    activeImage.style.display = "block";
    activeVideo.style.display = "none";
    activeImage.src = this.src;
    zoomedImg.src = this.src;
  });
});

// Handle video click event
const swiperVideos = document.querySelectorAll(".swiper-slide video");
swiperVideos.forEach((video) => {
  video.addEventListener("click", function () {
    activeImage.style.display = "none"; // Hide the image element
    activeVideo.style.display = "block"; // Show the video element
    activeVideo.src = this.querySelector("source").src;
    activeVideo.play(); // Optionally play the video when clicked
  });
});

$(".zoom")
  .on("mousemove", function (e) {
    const zoomer = $(this);
    const offsetX = e.offsetX || e.touches[0].pageX;
    const offsetY = e.offsetY || e.touches[0].pageY;
    const x = (offsetX / zoomer.width()) * 100;
    const y = (offsetY / zoomer.height()) * 100;
    zoomer.find("img").css("transform-origin", `${x}% ${y}%`);
    zoomer.find("img").css("transform", "scale(2)");
  })
  .on("mouseleave", function () {
    $(this).find("img").css("transform", "scale(1)");
  });

  // product details

const sliderThumbs = new Swiper('.slider__thumbs .swiper-container', { 
	direction: 'vertical', 
	slidesPerView: 4, 
	navigation: { 
		nextEl: ".product-details-section .swiper-button-next",
        prevEl: ".product-details-section .swiper-button-prev",
	},
	freeMode: true, 
	breakpoints: {
		0: {
			direction: 'horizontal',
		},
		768: { 
			direction: 'vertical', 
		}
	}
});

const mainSlider = new Swiper('.slider-main', {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1, 
        navigation: {
            nextEl: '.slider-main .swiper-button-next',
            prevEl: '.slider-main .swiper-button-prev',
        },
        thumbs: {
            swiper: sliderThumbs,
        },
    });


document.addEventListener('DOMContentLoaded', () => {
    // Select the button and input element
    const copyBtn = document.getElementById('copyLinkBtn');
    const input = document.querySelector('.URL');
    const field = document.querySelector('.field');

    // Proceed only if the copy button exists
    if (copyBtn) {
        // Add event listener for the copy button
        copyBtn.addEventListener('click', () => {
            input.select();

            if (document.execCommand("copy")) {
                field.classList.add("active");
                copyBtn.innerHTML = "<i class='bx bxs-check-circle fs-3'></i>";
                setTimeout(() => {
                    window.getSelection().removeAllRanges();
                    field.classList.remove("active");
                    copyBtn.innerHTML = "<i class='bx bxs-copy fs-3'></i>";
                }, 3000);
            }
        });
    }
});