document.addEventListener('DOMContentLoaded', () => {
    const mapElement = document.getElementById('map');

    if (mapElement) {
        const map = L.map('map').setView([51.505, -0.09], 13);

        // Add OpenStreetMap tiles
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        $('#add_new_address').on('shown.bs.modal', function () {
            map.invalidateSize();
        });

        // Add initial marker to the map
        const marker = L.marker([51.505, -0.09]).addTo(map).openPopup();

        // Add event listener for map click
        map.on('click', function (e) {
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;

            // Move the map to the clicked location
            map.setView([lat, lng], 13);

            // Add a marker to the clicked location
            const clickedMarker = L.marker([lat, lng]).addTo(map).openPopup();

            // Reverse geocode to get address
            reverseGeocode(lat, lng);
        });

        // Reverse geocoding function
        function reverseGeocode(lat, lng) {
            fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`)
                .then(response => response.json())
                .then(data => {
                    const address = data.display_name; // Full address
                    const addressComponents = data.address;

                    // Extract components
                    const city = addressComponents.city || addressComponents.town || addressComponents.village || '';
                    const country = addressComponents.country || '';
                    const zipCode = addressComponents.postcode || '';

                    // Update the fields with the address components
                    document.getElementById('address').value = address;
                    document.getElementById('country').value = country;
                    document.getElementById('city').value = city;
                    document.getElementById('zip_code').value = zipCode;
                })
                .catch(error => {
                    console.error('Geocoder failed due to:', error);
                });
        }

        // Toast message using SweetAlert
        function showToast(message) {
            Swal.fire({
                icon: "error",
                title: "Sorry",
                html: `
                    <h5><strong>${message}</strong></h5><br>
                    <p>For shipping outside China, please contact us at:<br><strong>info@eledtric.cn</strong></p>
                `,
            });
        }

        document.getElementById('get-location-btn').addEventListener('click', () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;

                    // Move the map to the user's location
                    map.setView([lat, lng], 13);

                    // Update marker position
                    marker.setLatLng([lat, lng]);

                    // Reverse geocode to get address
                    reverseGeocode(lat, lng);
                }, error => {
                    // Use showToast function for geolocation error
                    showToast("Unable to retrieve your location. Please try again.");
                });
            } else {
                // Use showToast function for unsupported geolocation
                showToast("Geolocation is not supported by your browser.");
            }
        });
    }
});
