$(document).ready(function () {
  // Check if the current page is exactly '/checkout'
  if (window.location.pathname === '/checkout') {
    
    // Tab functionality with slider
    $(".nav-tabs a").click(function (e) {
      e.preventDefault(); // Prevent default link behavior

      // Remove the 'active' class from all nav-tabs a and add it to the clicked one
      $(".nav-tabs a").removeClass("active");
      $(this).addClass("active");

      // Remove the 'active' class from all circles and add it to the current one
      $(".nav-tabs .circle").removeClass("active");
      $(this).find(".circle").addClass("active");

      // Show the active content and hide others
      var tabId = $(this).attr("href");
      $(".tab-pane").removeClass("show active");
      $(tabId).addClass("show active");
    });

    // Ensure the active circle has the 'active' class on page load
    $(".nav-tabs .active .circle").addClass("active");

      $(document).ready(function () {
        $('#flexCheckDefault').on('change', function () {
            if ($(this).is(':checked')) {
                // Hide the billing section
                $('#billing-section').hide();

            } else {
                $('#billing-section').show();
            }
        });
    });

    // Form validation
    (function () {
      "use strict";
      var form = document.getElementById("billing-details-form");

      // On form submission
      form.addEventListener(
        "submit",
        function (event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    })();
  }
});
