document.addEventListener("DOMContentLoaded", function() {
    const menuItems = document.querySelectorAll(".profile-actions ul li");
    const contentSections = document.querySelectorAll(".profile-content");

    menuItems.forEach(function(item) {
        item.addEventListener("click", function() {
            // Remove 'active' class from all <li>
            menuItems.forEach(function(li) {
                li.classList.remove("active");
            });

            // Add 'active' class to the clicked <li>
            item.classList.add("active");

            // Hide all content sections
            contentSections.forEach(function(section) {
                section.classList.add("d-none");
            });

            // Show the targeted content
            const target = item.getAttribute("data-target");
            document.getElementById(target).classList.remove("d-none");
        });
    });
});

    // Toggle for current password
    $("#toggle-current-password").on("click", function () {
        let passwordInput = $("#current-password");
        let icon = $(this);

        if (passwordInput.attr("type") === "password") {
            passwordInput.attr("type", "text");
            icon.removeClass("bx-hide").addClass("bx-show");
        } else {
            passwordInput.attr("type", "password");
            icon.removeClass("bx-show").addClass("bx-hide");
        }
    });

    // Toggle for new password
    $("#toggle-new-password").on("click", function () {
        let passwordInput = $("#new-password");
        let icon = $(this);

        if (passwordInput.attr("type") === "password") {
            passwordInput.attr("type", "text");
            icon.removeClass("bx-hide").addClass("bx-show");
        } else {
            passwordInput.attr("type", "password");
            icon.removeClass("bx-show").addClass("bx-hide");
        }
    });

    // Toggle for confirm-new password
    $("#toggle-confirm-new-password").on("click", function () {
        let passwordInput = $("#confirm-new-password");
        let icon = $(this);

        if (passwordInput.attr("type") === "password") {
            passwordInput.attr("type", "text");
            icon.removeClass("bx-hide").addClass("bx-show");
        } else {
            passwordInput.attr("type", "password");
            icon.removeClass("bx-show").addClass("bx-hide");
        }
    });

        // Initialize form validation
    (function () {
        "use strict";
        var form = document.getElementById("change-password-form");

        if (form) {
            form.addEventListener(
                "submit",
                function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    var newPassword = document.getElementById("new-password").value;
                    var confirmPassword = document.getElementById("confirm-new-password").value;
                    var passwordMismatchFeedback = document.getElementById("password-mismatch-feedback");

                    if (newPassword !== confirmPassword) {
                        event.preventDefault();
                        event.stopPropagation();
                        
                        passwordMismatchFeedback.style.display = "block";
                    } else {
                        passwordMismatchFeedback.style.display = "none";
                    }

                    form.classList.add("was-validated");
                },
                false
            );
        }
    })();

            (function () {
        "use strict";
        var form = document.getElementById("edit-user-form");

        if (form) {
            form.addEventListener(
                "submit",
                function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    form.classList.add("was-validated");
                },
                false
            );
        }
    })();